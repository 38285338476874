export default {
  text: '#585858',
  text2: '#cecece',
  primary: '#994a52',
  secondary: '#48141a',
  background: '#ffffff',
  backgroundSecondary: '#003054',
  light: '#FFF',
  dark: '#555555'
}
